import { useState, useEffect, useContext} from "react";
import styles from "./GroupComponentCreateNewAccount.module.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "../Spinner";
import { useGoogleLogin } from '@react-oauth/google';
import QRCode from "qrcode.react";
import AlertContext from '../../context/AlertContext'
import CountdownTimer from "./CountdownTimer";
import StepContext from '../../context/StepContext'

const GroupComponentCreateNewAccount = () => {

  const context = useContext(AlertContext);
    const {showAlert} = context;
    const {signUpStep, setSignUpStep,signUpEmail, setSignUpEmail} = useContext(StepContext);

  const googleSignUp = useGoogleLogin({
    onSuccess: async tokenResponse => {
      setLoading(true); // Start loading
      try {
        const response = await axios.get(
          'https://www.googleapis.com/oauth2/v3/userinfo',
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`
            }
          }
        )
        setCredentials({
          ...credentials,
          email: response.data.email,
          username: response.data.email,
        });
        // Log the updated credentials state
        // console.log("Updated credentials:", credentials);
        console.log(response.data.email)
        const credentials_signup = {
          email: response.data.email,
          username: response.data.email,
          password: '1',
        };
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/google_users/`,
            credentials_signup
          )
          .then((res) => {
            
            console.log(res);
            console.log(signUpstep)
            

            // Proceed to the next step
            // handleNextStep();
            setSignUpStep(signUpStep + 5)
          })
          .catch((err) => {if (err.response.data.detail.is_email_exists == true && err.response.data.detail.is_api_valid == true) {
            alert("Google Account Already Exists, Please Log In");
            handlePreviousStep();
          } else if (err.response.data.detail.is_email_exists == true && err.response.data.detail.is_api_valid == false) {
            setSignUpStep(signUpStep + 5)
          }
          }).finally(() => {
            setLoading(false); // Stop loading
          });

      } catch (err) {
        console.log(err)
      }
    }
  });


  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    verPass: "",
    username: "",
    verification_code: "",
    otp: "",
    exchange: "",
    api_key: "",
    secret_key: "",
  });
  useEffect(() => {
    console.log("Updated credentials:", credentials);
    console.log("signUpEmail:", signUpEmail)
  }, [credentials]);

  const [loading, setLoading] = useState(false); // State to manage loading state
  const [secretKey, setSecretKey] = useState("");
  const [qrCodeValue, setQRCodeValue] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (secretKey) {
      // Generate the value for the QR code
      setQRCodeValue(`otpauth://totp/ElyseesWealth:${credentials.email}?secret=${secretKey}&issuer=ElyseesWealth`);
    }
  }, [credentials.email, secretKey]);

  const handleNextStep = () => {
    setSignUpStep(signUpStep + 1);
  };

  const handlePreviousStep = () => {
    setSignUpStep(signUpStep - 1);
  };

  const onChange = (e) => {
    if (e.target.name === "email") {
      // If the email input field changes, set the username to the email address
      setCredentials({
        ...credentials,
        [e.target.name]: e.target.value,
        username: e.target.value, // Set username equal to the email address
      });
      // console.log(credentials)
    } else {
      // For other input fields, simply update the state
      setCredentials({
        ...credentials,
        [e.target.name]: e.target.value,
      });
      // console.log(credentials)
    }
  };
  // setCredentials({...credentials, [e.target.name]: e.target.value})


  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(credentials);
    // You can perform any validation before proceeding to the next step

    if (signUpStep === 1) {
      handleNextStep();
    }

    // If it's the final step, submit the form data to the backend API
    if (signUpStep === 2) {
      if ((credentials.password === credentials.verPass) && credentials.password.length >3) {
        // Submit form data to backend API
        setLoading(true); // Start loading
        const credentials_signup = {
          email: credentials.email,
          username: credentials.username,
          password: credentials.password,
        };
        console.log("Submitting form data to backend API");
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/users/`,
            credentials_signup
          )
          .then((res) => {
            console.log(res);
            // Proceed to the next step
            handleNextStep();
          })
          .catch((err) => {
            if (err.response.data.detail.is_email_exists == true && err.response.data.detail.is_api_valid == true) {
              alert("Email Already Exists");
              handlePreviousStep();
            } else if (err.response.data.detail.is_email_exists == true && err.response.data.detail.is_api_valid == false) {
              setSignUpStep(signUpStep + 4)
            }
          })
          .finally(() => {
            setLoading(false); // Stop loading
          });
      } else if (!(credentials.password === credentials.verPass)){
        // alert("Password does not match");
        showAlert("Password does not match", "danger");
      }
      else {
        showAlert("Password must be greater than 3 characters", "danger");
      }
    }

    if (signUpStep === 3) {
      // Submit form data to backend API
      const credentials_verification = {
        email: credentials.email,
        verification_code: credentials.verification_code,
      };
      console.log("credentials_verification", credentials_verification);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/verify/`,
          credentials_verification
        )
        .then((res) => {
          console.log(res);
          handleNextStep();
        })
        .catch((err) => {
          console.error(err["response"]["data"]);
          showAlert("Incorrect Verification Code", "danger");
        });
    }
    if (signUpStep === 4) {
      let credentials_2fa = {}
      if (signUpEmail) {
        credentials_2fa = {
          email: signUpEmail,
        }
      } else {
      credentials_2fa = {
        email: credentials.email,
      }
    }

      console.log('response', credentials_2fa)
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/enable_2fa/`, credentials_2fa);
        console.log(response)
        setSecretKey(response.data.secret_key);
        setError(null); // Reset error
        handleNextStep();
      } catch (error) {
        setError(error.response.data);
      }
    }

    if (signUpStep === 5) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/validate_2fa/`,
          {
            // email: credentials.email,
            email: signUpEmail,
            token: credentials.otp
          }
        );
        console.log('a',response)
        handleNextStep();
      } catch (error) {
        showAlert('Invalid OTP', 'danger')
        credentials.otp = '';
        document.getElementById('otp').value = ''
      }
    }

    if (signUpStep === 6) {
      let api_info = {}
      // Capture API key, API secret, and exchange information from state
      setLoading(true)
      if (signUpEmail) {
        api_info = {
          email: signUpEmail,
          exchange: credentials.exchange,
          api_key: credentials.api_key,
          secret_key: credentials.secret_key,
        }
      } else {
          api_info = {
            email: credentials.email,
            exchange: credentials.exchange,
            api_key: credentials.api_key,
            secret_key: credentials.secret_key,
        };
      }

      try {
        // Update API keys
        const updateResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/keys/`,
          api_info
        );
        console.log("API keys updated:", updateResponse.data);

        // Validate API keys
        const validateResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/validate_keys/`,
          api_info
        );
        console.log("server_response", validateResponse.data);

        if (validateResponse.data === "Invalid API key or secret") {
          setLoading(false)
          // to show step 5
          showAlert("Invalid API, Try Again", "danger")
        } else {
          setLoading(false)
          showOpSuccess();
        }
      } catch (error) {
        setLoading(false)
        // Handle error
        console.error("Error updating/validating API keys:", error);
        // alert(error);
        showAlert("Error Updating/validating API keys", "danger")
      }
    }


    if (signUpStep === 7) {
      setLoading(true)
      // Capture API key, API secret, and exchange information from state
      const api_info = {
        email: credentials.email,
        exchange: credentials.exchange,
        api_key: credentials.api_key,
        secret_key: credentials.secret_key,
      };
      // console.log(credentials.email, exchange, apiKey, secretKey)

      try {
        // Update API keys
        const updateResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/keys/`,
          api_info
        );
        console.log("API keys updated:", updateResponse.data);

        // Validate API keys
        const validateResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/validate_keys/`,
          api_info
        );
        console.log("server_response", validateResponse.data);
        setLoading(false)
        // to show step 5
        showOpSuccess();
      } catch (error) {
        setLoading(false)
        // Handle error
        console.error("Error updating/validating API keys:", error);
        // alert(error);
        showAlert("Error Updating/validating API keys", "danger")
      }
    }


    // } else {
    //   // Otherwise, proceed to the next step
    //   handleNextStep();
    // }
  };
  const showOpSuccess = () => {
    handleNextStep();
    console.log("showOpSuccess executed");
    setTimeout(() => {
      navigate("/login");
    }, 6000);
  };
    

  return (
    <>
      {signUpStep === 1 && (
        <div className={styles.rectangleParent} style={{fontFamily: "var(--font-nunito)"}}>
          <div className={styles.frameChild} />
          <div className={styles.signupTitle}>
            <h1 className={styles.createAnAccount}>Create an account</h1>
            <div className={styles.enterYourEmail}>
              Enter your email to sign up to Elysees Wealth Management
            </div>
          </div>
          <div className={styles.emailInput}>
            <div className={styles.email}>Email</div>
            <div className={styles.inputField}>
              <input
                type="email"
                className={styles.inputField}
                style={{
                  display: "inline-block",
                  minWidth: "100%",
                  background: "rgba(0, 0, 0, 0.2)",
                  paddingLeft: "10px",
                }}
                placeholder="name@example.com"
                id="email"
                name="email"
                onChange={onChange}
              />

              {/* <input type="text" className={styles.inputField}  style={{display: "inline-block", minWidth: "100%", background: "rgba(0, 0, 0, 0.2)", paddingLeft: "10px"}} placeholder="username" id="username" name="username" onChange={onChange}/> */}
              <div className={styles.emailAddress}></div>
            </div>
          </div>
          <Link
            role="button"
            className={styles.inputField1}
            onClick={handleSubmit}
            style={{ border: "var(--color-cadetblue-200)" }}
          >
            {/* <div className={styles.password}>Password</div> */}
            Sign up with email
            {/* <div className={styles.signUpWith}>Sign up with email</div> */}
          </Link>
          <div className={styles.socialLogin}>
            <div className={styles.socialIcons}>
              <div className={styles.iconShapes} />
            </div>
            <div className={styles.orContinueWith}>or continue with</div>
            <div className={styles.socialIcons1}>
              <div className={styles.socialIconsChild} />
            </div>
          </div>
          <div className={styles.googleLogin}>
            {loading && <Spinner />}
            <Link
              // to="/login"
              onClick={() => googleSignUp()}
              className={`text-decoration-none ${styles.loginregisterWith}`}
              style={{ color: "var(--color-cadetblue-100)" }}
            >
              <img className={styles.googleIcon} alt="" src="/google.svg" />
              <div className={styles.google}>Google</div>
            </Link>
            <div className={styles.byClickingContinueContainer}>
              <span>{`By clicking continue, you agree to our `}</span>
              <span className={styles.termsOfService}>Terms of Service</span>
              <span>{` and `}</span>
              <span className={styles.privacyPolicy}>Privacy Policy</span>
            </div>
          </div>
        </div>
      )}

      {/* {loading && (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Adjust height to cover the entire viewport
        }}
      >
        <div
          className="spinner"
          style={{
            width: '50px',
            height: '50px',
            border: '5px solid #f3f3f3',
            borderTop: '5px solid #3498db',
            borderRadius: '50%',
            animation: 'spin 1s linear infinite',
          }}
        ></div>
      </div>
    )} */}

      {signUpStep === 2 && (
        <div className={styles.rectangleParent} style={{}}>
          <div className={styles.frameChild} />
          <div className={styles.signupTitle}>
            {loading && <Spinner />}

            <h1 className={styles.createAnAccount}>Create Password</h1>
            <div className={styles.enterYourEmail}>
              Create a strong password
            </div>
          </div>
          <div className={styles.emailInput}>
            <div className={styles.email}>Password</div>
            <div className={styles.inputField}>
              <input
                type="password"
                className={styles.inputField}
                style={{
                  display: "inline-block",
                  minWidth: "100%",
                  background: "rgba(0, 0, 0, 0.2)",
                  paddingLeft: "10px",
                }}
                id="password"
                name="password"
                onChange={onChange}
              />
              <div className={styles.emailAddress}></div>
            </div>
          </div>
          <div className={styles.emailInput}>
            <div className={styles.email}> Verify Password</div>
            <div className={styles.inputField}>
              <input
                type="password"
                className={styles.inputField}
                style={{
                  display: "inline-block",
                  minWidth: "100%",
                  background: "rgba(0, 0, 0, 0.2)",
                  paddingLeft: "10px",
                }}
                id="verPass"
                name="verPass"
                onChange={onChange}
              />
              <div className={styles.emailAddress}></div>
            </div>
          </div>
          <Link
            role="button"
            className={styles.inputField1}
            onClick={handleSubmit}
            style={{ border: "var(--color-cadetblue-200)" }}
          >
            {/* <div className={styles.signUpWith}>Confirm</div> */}
            Confirm
          </Link>
        </div>
      )}
      {signUpStep === 3 && loading === false && (
        <div className={styles.rectangleParent} style={{}}>
          <div className={styles.frameChild} />
          <div className={styles.signupTitle}>
            <h1 className={styles.createAnAccount}>Confirm Email</h1>
            <div className={styles.enterYourEmail}>
              Enter your verification code
            </div>
          </div>
          <div className={styles.emailInput}>
            <div className={styles.email}>Enter Code</div>
            <div className={styles.inputField}>
              <input
                className={styles.inputField}
                style={{
                  display: "inline-block",
                  minWidth: "100%",
                  background: "rgba(0, 0, 0, 0.2)",
                  paddingLeft: "10px",
                }}
                id="verification_code"
                name="verification_code"
                onChange={onChange}
              />
              <div className={styles.emailAddress}></div>
            </div>
          </div>
          <Link
            role="button"
            to="/signup3"
            className={styles.inputField1}
            onClick={handleSubmit}
            style={{ border: "var(--color-cadetblue-200)" }}
          >
            {/* <div className={styles.signUpWith}>Confirm</div> */}
            Confirm
          </Link>
          <div className={styles.socialLogin}>
            <div className={styles.socialIcons}>
              <div className={styles.iconShapes} />
            </div>
            {/* <Link className="text-white text-decoration-none" disabled
            onClick={resendCode}  >
              <div className={`${styles.orContinueWith}`}>Resend Code</div>
            </Link> */}
            <CountdownTimer credentials={credentials}/>
            <div className={styles.socialIcons1}>
              <div className={styles.socialIconsChild} />
            </div>
          </div>
        </div>
      )}

{signUpStep === 4 && (
        <div className={styles.rectangleParent} style={{}}>
          <div className={styles.frameChild} />
          <div className={`mx-auto ${styles.signupTitle}`} style={{width: "100%"}}>
            <h1 className={`${styles.createAnAccount} mx-auto`}>Create 2FA</h1>
            <div className="mx-auto"><img src="/2fa.png" alt="2fa" /></div>
            <div className={`mx-auto ${styles.enterYourEmail}`}>
              Enable the Two Factor Authentication
            </div>
          </div>
          <Link
            role="button"
            className={styles.inputField1}
            onClick={handleSubmit}
            style={{ border: "var(--color-cadetblue-200)" }}
          >
            {/* <div className={styles.signUpWith}>Enable 2FA</div> */}
            Enable 2FA
          </Link>
        </div>
      )}

{signUpStep === 5 && (
        <div className={styles.rectangleParent} style={{}}>
          <div className={styles.frameChild} />
          <div className={styles.signupTitle}>
            <h1 className={`mx-auto ${styles.createAnAccount}`}>Create 2FA</h1>
            <div className={styles.enterYourEmail}>
            {secretKey && (
                  <div>
                    <p>2FA enabled successfully!</p>
                    <p><b>Secret Key: {secretKey}</b></p>
                    <p>Add this Secret Key to your Google Authenticator App to get OTP for your next login or scan the QR Code</p>
                    <QRCode value={qrCodeValue} />
                    <div className={`mx-auto ${styles.enterYourEmail}`}>
                    Enter the Google Authenticator Code:
                      <div className={styles.inputField}>
                        <input
                          type='password'
                          className={styles.inputField}
                          style={{
                            display: "inline-block",
                            minWidth: "100%",
                            background: "rgba(0, 0, 0, 0.2)",
                            paddingLeft: "10px",
                          }}
                          id="otp"
                          name="otp"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>)}
                <Link
                  role="button"
                  className={`mt-3 ${styles.inputField1}`}
                  onClick={handleSubmit}
                  style={{ border: "var(--color-cadetblue-200)" }}
                >
                  {/* <div className={styles.signUpWith}>Next</div> */}
                  Next
                </Link>
            </div>
          </div>
        </div>
      )}

      {signUpStep === 6 && (
        <div className="">
          <div className={styles.rectangleParent} style={{}}>
            <div className={styles.frameChild} />
            <div className={styles.signupTitle}>
            {loading && <Spinner />}
              <h1 className={styles.createAnAccount}>Enter API</h1>
            </div>
            {/* dropdown */}

            <div className={styles.emailInput}>
            <div className={styles.email}>Exchange</div>
              <div className={styles.inputField}>
                  <select
                      className="form-select text-white"
                      aria-label="Default select example"
                      style={{
                        width: "100%",
                        backgroundColor: "var(--color-gray-200)",
                        borderColor: "var(--color-cadetblue)", fontFamily: "var(--font-nunito)", position: "relative", zIndex: "1000"
                      }}
                      value={credentials.exchange} // Set value of select element based on state
                      onChange={(e) => setCredentials({...credentials,exchange: e.target.value})}// Event listener for change in value
                    >
                      <option value="binance">Binance</option>
                      <option value="bybit">ByBit</option>
                    </select>
                
                <div className={styles.emailAddress}></div>
              </div>
            </div>
            {/* API Key */}
            <div className={styles.emailInput}>
              <div className={styles.email}> API Key</div>
              <div className={styles.inputField}>
                <input
                  type="text"
                  className={styles.inputField}
                  style={{
                    display: "inline-block",
                    minWidth: "100%",
                    background: "rgba(0, 0, 0, 0.2)",
                    paddingLeft: "10px",
                  }}
                  id="api_key"
                  name="api_key"
                  onChange={onChange}
                  value={credentials.api_key}
                />
                <div className={styles.emailAddress}></div>
              </div>
            </div>
            {/* Secret Key */}
            <div className={styles.emailInput}>
              <div className={styles.email}> API Secret</div>
              <div className={styles.inputField}>
                <input
                  type="password"
                  className={styles.inputField}
                  style={{
                    display: "inline-block",
                    minWidth: "100%",
                    background: "rgba(0, 0, 0, 0.2)",
                    paddingLeft: "10px",
                  }}
                  id="secret_key"
                  name="secret_key"
                  onChange={onChange}
                  value={credentials.secret_key}
                />
                <div className={styles.emailAddress}></div>
              </div>
            </div>
            {/* Submit Button */}
            <Link
              role="button"
              // to="/login"
              className={styles.inputField1}
              onClick={handleSubmit}
              style={{ border: "var(--color-cadetblue-200)" }}
            >
              
              {/* <div className={styles.signUpWith}>Confirm Submit</div> */}
              Confirm Submit
            </Link>
          </div>
        </div>
      )}


      {signUpStep === 7 && (
        <div>
          <div
            className={styles.createAnAccount}
            style={{
              width: "100%",
              marginTop: "80px",
              fontSize: "var(--font-size-39xl)",
            }}
          >
            OPERATION SUCCESFULL
          </div>{" "}
          <p className={styles.email}>
            You Will Be Redirected To Login Shortly....
          </p>
        </div>
      )}
    </>
  );
};

export default GroupComponentCreateNewAccount;
