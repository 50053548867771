import styles from "../components/startFreeTrial/ONBOARDING.module.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import BlogItem from "../components/blogs/BlogItem";
import BlogCorousel from "../components/blogs/BlogCorousel";
import axios from "axios";
import Settings from "../components/dashboard/Settings";

const Blog = () => {
  const [blogItems, setBlogItems] = useState([]);

  useEffect(() => {
    document.getElementById("blogDiv").scroll(0, 0);

    // Fetch data from the backend API
    const fetchBlogItems = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/blogs/`
        ); // replace with your actual API endpoint
        setBlogItems(response.data);
      } catch (error) {
        console.error("Error fetching blog items:", error);
      }
    };

    fetchBlogItems();
  }, []);

  // const blogItemsData = [
  //   {
  //     title: "Tether",
  //     description: "Tether is a cryptocurrency stablecoin, launched by the company Tether Limited Inc. in 2014. As of January 2024, Tether's website lists fourteen protocols and blockchains on which Tether has been minted.Tether is a cryptocurrency stablecoin, launched by the company Tether Limited Inc. in 2014. As of January 2024, Tether's website lists fourteen protocols and blockchains on which Tether has been minted.",
  //     imageSrc: "/rectangle-39253@2x.png",
  //   },
  //   {
  //     title: "Bitcoin",
  //     description: "Bitcoin was designed to be independent of any government or central bank. Instead, it relies on blockchain technology, a decentralized public ledger that Bitcoin was designed to be independent of any government or central bank. Instead, it relies on blockchain technology, a decentralized public ledger that",
  //     imageSrc: "/rectangle-39253-1@2x.png",
  //   },
  //   {
  //     title: "Binance Coin",
  //     description: "Binance Coin (BNB) Binance is one of the world's biggest cryptocurrency exchanges. The Binance Coin (BNB) was created as a utility token for use as a medium Binance Coin (BNB) Binance is one of the world's biggest cryptocurrency exchanges. The Binance Coin (BNB) was created as a utility token for use as a medium",
  //     imageSrc: "/rectangle-39253-2@2x.png",
  //   },

  // ];

  return (
    <div
      id="blogDiv"
      style={{
        backgroundImage: "url(/back2.jpg)",
        backgroundSize: "cover",
      }}
    >
      <Header />
      <div style={{ backgroundColor: "var(--color-gray-200)" }}>
        <section
          className="container text-white"
          style={{
            width: "100%",
            paddingTop: "40px",
            fontFamily: "var(--font-nunito)",
          }}
        >
          <div className="container ">
            <div
              className="resHeading text-center fw-bolder"
              style={{ fontSize: "var(--font-size-29xl)" }}
            >
              Blog
            </div>
            <div className="mt-1 text-center">
              <p>
                Remember, informed trading decisions are key to success in the
                fast-paced world of cryptocurrency futures trading.
              </p>
            </div>
          </div>
          <BlogCorousel />

          <div className="container  mt-2">
            <div className="d-flex row row-cols-1 row-cols-md-3">
              {blogItems.map((item, index) => (
                <BlogItem
                  key={index}
                  id={item.id}
                  title={item.title}
                  description={item.content}
                  imageSrc={item.path}
                />
              ))}
            </div>
          </div>
        </section>
        <Settings />
        <Footer />
      </div>
    </div>
  );
};

export default Blog;
