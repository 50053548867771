import styles from "./ONBOARDING.module.css";
import Header from "../Header";
import Footer from "../Footer";
import { useEffect, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import StepContext from "../../context/StepContext";
const Onboarding = () => {
  const context = useContext(StepContext)
  const {onboardingStep, setOnboardingStep} = context

  useEffect(() => {
    // Scroll to the top when component mounts
    document.getElementById("mainDiv").scroll(0,0)
    
    console.log("scroll top")
  }, [])

 

  const handleNextStep = () => {
    if (onboardingStep === 1) {
      setOnboardingStep(2);
      
    } else {
      setOnboardingStep(1);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className={styles.onboarding} id="mainDiv">
      <img className={styles.bodyIcon} alt="" src="/back2.jpg" />
      <Header />
      <main className={styles.rectangleParent}>
        <div className={styles.frameChild} />
        {onboardingStep === 1 && (
          <section className={styles.heroContent}>
            <div className={styles.frameParent}>
              <div className={styles.useOfStablesailWrapper}>
                <h1 className={styles.useOfStablesail}>Use of StableSail</h1>
              </div>
              <h1 className={styles.loremIpsumDolorContainer}>
                <ul className={styles.loremIpsumDolorSitAmetCon}>
                  <li
                    className={styles.loremIpsumDolor}
                  >{`Lorem ipsum dolor sit amet consectetur. `}</li>
                  <li className={styles.sitEgestasLectus}>
                    Sit egestas lectus arcu tellus convallis sit faucibus.
                  </li>
                  <li
                    className={styles.urnaOrciPellentesque}
                  >{` Urna orci pellentesque augue quis lorem `}</li>
                  <li className={styles.suspendisseAmetDiam}>
                    suspendisse amet. Diam hendrerit in fames nisl et eget nunc
                    nec.
                  </li>
                  <li> Tempor aliquam lectus quis maecenas.</li>
                </ul>
              </h1>
            </div>
          </section>
        )}
        {onboardingStep === 1 && (
          <section className={styles.cta}>
            <div className={styles.rectangleGroup}>
              <div className={styles.frameItem} />
              <h1 className={styles.watchOrSkimContainer}>
                <p className={styles.watchOrSkim}>
                  Watch or skim our onboarding video to jump right into
                  automated trading. Discover how to set up your bot and create
                  your strategy. 
                </p>
                <p className={styles.dontMissOut}>
                  👉 Don’t miss out on optimizing your trading journey. 
                </p>
              </h1>
              <div className={styles.tutorialButton}>
                <div>
                  <Link onClick={handleNextStep} className="btn btn-lg text-white fw-bold px-5 py-3 mt-4" tabIndex="-1" role="button" aria-disabled="true" style={{backgroundColor: "var(--color-cadetblue)"}}>Start Tutorial</Link>
                </div>
              </div>
            </div>
          </section>
        )}

        {onboardingStep === 2 && (<section className="mt-5" style={{ width: "100%" }}>
          <div className="container mx-auto row justify-content-center">
            <div className="col-12 rounded-4">
              <video style={{border: "6px solid var(--color-cadetblue)", width: "100%", height: "100%", borderRadius:"30px"}} controls src="/stableSailVideo.mp4"></video>
            </div>
          </div>
          <Link onClick={handleNextStep} className="btn fw-bold text-white px-5 py-3 mt-4 mx-auto d-block" tabIndex="-1" role="button" aria-disabled="true" style={{backgroundColor: "var(--color-cadetblue)", maxWidth: "250px"}}>Onboarding Page</Link>
        </section>)}

        <Footer />
      </main>
    </div>
  );
};

export default Onboarding;