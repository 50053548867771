import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';

const BlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlogDetail = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/blogs/${id}`);
        setBlog(response.data);
        console.log(response.data)
      } catch (error) {
        console.error("Error fetching blog details:", error);
      }
    };

    fetchBlogDetail();
  }, [id]);

  if (!blog) return <div>Loading...</div>;

  return (
    <div style={{ backgroundImage: "url(/back2.jpg)", backgroundSize: "cover" }}>
      <Header />
      <div style={{ backgroundColor: "var(--color-gray-200)" }}>
        <section className="container text-white" style={{ width: "100%", paddingTop: "40px", fontFamily: "var(--font-nunito)" }}>
          <div className="container">
            <div className="text-center fw-bolder" style={{ fontSize: "var(--font-size-29xl)" }}>
              {blog.title}
            </div>
            <div className="mt-1 text-center">
             <div className='mx-auto' style={{width: "300px"}}> <img src={blog.path} alt={blog.title} className="img-fluid" /></div>
              <p>{blog.content}</p>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default BlogDetail;
