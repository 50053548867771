import styles from "../components/startFreeTrial/ONBOARDING.module.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useEffect } from "react";
import Settings from "../components/dashboard/Settings";

const AboutUs = () => {
  useEffect(() => {
    document.getElementById("mainSection").scroll(0, 0);
  }, []);
  return (
    <div className={styles.onboarding}>
      <img className={styles.bodyIcon} alt="" src="/back2.jpg" />
      <Header />
      <main className={styles.rectangleParent}>
        <div className={styles.frameChild} />

        <section
          id="mainSection"
          className="container text-white"
          style={{
            width: "100%",
            marginTop: "30px",
            fontFamily: "var(--font-nunito)",
          }}
        >
          <div
            className="container rounded-4"
            style={{ backgroundColor: "var(--color-gray-200)" }}
          >
            <div className="resHeading" style={{ fontSize: "var(--font-size-29xl)" }}>
              Unlock the Power of Automated Trading with Elysees Wealth Management's Bot
            </div>
            <div className="mt-4">
              <p>
                At Elysees Wealth Management, we're pioneering the forefront of cryptocurrency
                trading with a singular vision: to empower traders of all levels
                with the tools and insights needed to navigate the dynamic world
                of digital assets. Founded by a team of seasoned traders,
                developers, and fintech enthusiasts, we've combined decades of
                market experience with cutting-edge technology to create a
                trading bot platform that's both powerful and user-friendly.
              </p>
              <p>
                Our journey began with a shared frustration over the missed
                opportunities and the exhaustive nature of manual trading. We
                saw the potential for automation to transform the way people
                trade - making it more efficient, less time-consuming, and
                accessible to a broader audience. From this vision, Elysees Wealth Management
                was born: a platform designed to democratize trading by making
                sophisticated algorithms available to everyone.
              </p>
              <p>
                At the heart of Elysees Wealth Management is our commitment to innovation,
                security, and transparency. We believe in providing our users
                with a tool that not only enhances their trading strategy but
                does so with the utmost integrity and safety. That's why we've
                built Elysees Wealth Management to operate with limited permissions, ensuring
                your funds can never be withdrawn, only traded, based on your
                customized settings.
              </p>

              <p>
                Beyond technology, we're passionate about building a community
                of informed traders. Education is a cornerstone of our platform,
                and we're dedicated to sharing knowledge that helps our users
                make informed decisions. From detailed guides on setting up your
                bot to insights into market trends, we strive to be a resource
                for continuous learning and growth.
              </p>

              <p>
                The future of trading is automated, and at Elysees Wealth Management, we're
                excited to be leading the charge. Whether you're a seasoned
                trader looking to optimize your strategy or new to the
                cryptocurrency space, our platform is designed to help you
                achieve your trading goals with confidence. Join us on this
                journey, and let's navigate the future of trading together.
              </p>
            </div>
          </div>
        </section>
        <Footer />
      </main>
      <Settings />
    </div>
  );
};

export default AboutUs;
