import styles from "./Header.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import "../pages/css/responsive.css"

const Header = () => {
  let location = useLocation();

  const handleLogout = () => {
    sessionStorage.removeItem("accessToken");
    console.log("logout is clicked");
    window.location.href = "/login";
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 750);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 750);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <nav
      className="fw-bold navbar navbar-expand-md navbar-dark"
      aria-label="Fourth navbar example"
      style={{
        width: "100%",
        backgroundColor: "rgba(0,0,0,0.3)",
        fontFamily: "var(--font-nunito)",
      }}
    >
      <div className="container-fluid px-md-5">
        <Link className="navbar-brand" to="/">
          <div>
            <img
              className={styles.ElyseesWealthLogo2Icon}
              loading="lazy"
              alt=""
              src="/ElyseesWealth-logo-2@2x.png"
              style={{ width: "66px", height: "66px" }}
            />
            Elysees Wealth Management
          </div>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarsExample04"
          aria-controls="navbarsExample04"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="ps-3 ps-md-0 collapse navbar-collapse"
          id="navbarsExample04"
        >
          <ul className="navbar-nav mx-auto mb-2 mb-md-0">
            <li className="nav-item">
              <Link
                className={`nav-link active ${styles.scaleUp} `}
                aria-current="page"
                to="/"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link active ${styles.scaleUp} mx-md-3`}
                aria-current="page"
                to="/howitworks"
              >
                How It Works
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link active ${styles.scaleUp} me-md-3`}
                aria-current="page"
                to="/blog"
              >
                Blog
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link active ${styles.scaleUp} `}
                aria-current="page"
                to="/aboutus"
              >
                About Us
              </Link>
            </li>
          </ul>
          <div className={isSmallScreen ? "row width150" : ""}>
            {!sessionStorage.getItem("accessToken") ? (
              <>
                <Link
                  type="button"
                  className={`btn px-3 text-white text-decoration-underline ${
                    location.pathname === "/login" ? "d-none" : null
                  } ${styles.scaleUp} ${isSmallScreen ? "borderWhite" : ""}`}
                  to="/login"
                  style={{ backgroundColor: "transparent" }}
                >
                  Sign In
                </Link>
                <Link
                  type="button"
                  className={`btn text-white ${
                    location.pathname === "/signup" ||
                    location.pathname === "/startfreetrial" ||
                    location.pathname === "/onboarding"
                      ? "d-none"
                      : null
                  } px-3 ms-2`}
                  to="/startfreetrial"
                  style={{ backgroundColor: "var(--color-cadetblue)" }}
                >
                  Start for Free
                </Link>
                <Link
                  type="button"
                  className={`btn text-white ${
                    location.pathname === "/login" ||
                    location.pathname === "/signup" ||
                    location.pathname === "/startfreetrial" ||
                    location.pathname === "/onboarding" ||
                    location.pathname === "/aboutus" ||
                    location.pathname === "/blog"
                      ? "d-inline"
                      : "d-none"
                  } px-3 ms-2 mt-2 mt-md-0 ${
                    location.pathname === "/login" ||
                    location.pathname === "/aboutus" ||
                    location.pathname === "/blog"
                      ? "border-white"
                      : null
                  }`}
                  to="/requestdemo"
                  style={{
                    backgroundColor:
                      location.pathname === "/login" ||
                      location.pathname === "/aboutus" ||
                      location.pathname === "/blog"
                        ? "transparent"
                        : "var(--color-cadetblue)",
                  }}
                >
                  Request Demo
                </Link>
              </>
            ) : (
              <>
                <Link
                  type="button"
                  className={`btn text-white 
               px-3 ms-2`}
                  onClick={handleLogout}
                  style={{ backgroundColor: "var(--color-cadetblue)" }}
                >
                  Log out
                </Link>
                <Link
                  type="button"
                  className={`btn text-white 
               px-3 ms-2`}
                  role="button"
                  data-bs-toggle="modal"
                  data-bs-target="#dashboardModal"
                  style={{ backgroundColor: "var(--color-cadetblue)" }}
                >
                  Settings
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
