import styles from "../signup/GroupComponentCreateNewAccount.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import StepContext from "../../context/StepContext";
import axios from "axios";
import AlertContext from "../../context/AlertContext";
import CountdownTimer from "../signup/CountdownTimer";
import Spinner from "../Spinner";
import "../../pages/css/responsive.css"

const StartFreeTrialContent = () => {
  const context = useContext(AlertContext);
  const { showAlert } = context;
  const { signUpStep, setSignUpStep, signUpEmail, setSignUpEmail } =
    useContext(StepContext);
  const [loading, setLoading] = useState(false); // State to manage loading state
  useEffect(() => {
    // Set signUpStep to 1 when the component mounts
    setSignUpStep(1);
  }, []);

  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setLoading(true); // Start loading
      try {
        const response = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );
        setCredentials({
          ...credentials,
          email: response.data.email,
          username: response.data.email,
        });
        // Log the updated credentials state
        const credentials_signup = {
          email: response.data.email,
          username: response.data.email,
          password: "1",
        };
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/google_users/`,
            credentials_signup
          )
          .then((res) => {
            navigate("/signup", setSignUpStep(signUpStep + 5));
          })
          .catch((err) => {
            if (
              err.response.data.detail.is_email_exists == true &&
              err.response.data.detail.is_api_valid == true
            ) {
              showAlert(
                "Google Account Already Exists, Please Log In",
                "danger"
              );
            } else if (
              err.response.data.detail.is_email_exists == true &&
              err.response.data.detail.is_api_valid == false
            ) {
              navigate("/signup", setSignUpStep(signUpStep + 5));
            }
          })
          .finally(() => {
            setLoading(false); // Stop loading
          });
      } catch (err) {
        console.log(err);
      }
    },
  });

  const [credentials, setCredentials] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    verification_code: "",
  });

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    console.log(credentials);
  };

  useEffect(() => {
    if (credentials.email) {
      setSignUpEmail(credentials.email);
      // console.log(signUpEmail, credentials.email)
    }
  }, [credentials.email, setSignUpEmail]);

  const [step, setStep] = useState(1);
  const handleNextStep = () => {
    setStep(step + 1);
  };
  const submit = async (e) => {
    e.preventDefault();
    if (step === 1) {
      console.log("step", signUpStep);
      if (!credentials.email.includes("@")) {
        showAlert("Enter a Valid Email Address", "danger");
      } else if (credentials.password.length < 4) {
        console.log("2");
        showAlert("Password must be greater than 3 characters", "danger");
      } else {
        setLoading(true); // Start loading
        const credentials_signup = {
          email: credentials.email,
          username: credentials.email,
          password: credentials.password,
        };
        console.log("Submitting form data to backend API");
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/users/`,
            credentials_signup
          )
          .then((res) => {
            console.log(res);
            // Proceed to the next step
            handleNextStep();
          })
          .catch((err) => {
            if (
              err.response.data.detail.is_email_exists == true &&
              err.response.data.detail.is_api_valid == true
            ) {
              showAlert("Email Already Exists, Please Log in...", "danger");
            } else if (
              err.response.data.detail.is_email_exists == true &&
              err.response.data.detail.is_api_valid == false
            ) {
              navigate("/signup", setSignUpStep(signUpStep + 5));
            }
          })
          .finally(() => {
            setLoading(false); // Stop loading
          });
      }
    }
    if (step === 2) {
      setLoading(false)
      const credentials_verification = {
        email: credentials.email,
        verification_code: credentials.verification_code,
      };
      console.log("credentials_verification", credentials_verification);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/verify/`,
          credentials_verification
        )
        .then((res) => {
          console.log(res);
          setLoading(false)
          navigate(
            "/signup",
            setSignUpStep(signUpStep + 3),
            setSignUpEmail(credentials_verification.email)
          );
        })
        .catch((err) => {
          console.error(err["response"]["data"]);
          showAlert("Incorrect Verification Code", "danger");
          // alert(err["response"]["data"]["detail"]);
        });
    }
  };

  return (
    <section
      className=""
      style={{ width: "100%", fontFamily: "var(--font-nunito)" }}
    >
      <div className="container">
        <div className="row  d-flex align-items-md-start text-white">
        <div className="col-md-7 text-center pe-md-5">
          <p className="resHeading" style={{ fontSize: "var(--font-size-29xl)" }}>
            Enhance Your Trading with a StableSail Free Trial
          </p>
          <p className="resText" style={{ fontSize: "var(--font-size-13xl)" }}>
            Experience the full power of StableSail totally free for 30 days
          </p>
          <p  style={{ fontSize: "var(--font-size-5xl)" }}>
            No payment needed. We won’t ask for your credit card. We are that
            certain that after 1 month you will beg to continue!
          </p>
        </div>
        {step === 1 && (
          <div className="col-md-5">
            <div className="">
              <div
                className="container text-white p-3 rounded-4"
                style={{ backgroundColor: "var(--color-gray-200)" }}
              >
                <div className="mb-3">
                  <h4
                    className="fw-bold resText"
                    style={{ fontSize: "var(--font-size-13xl)" }}
                  >
                    Account Creation Form
                  </h4>
                </div>

                <form className="row g-3">
                  <div className="col-md-6">
                    <label
                      for=""
                      className="form-label"
                      style={{ fontSize: "var(--font-size-base)" }}
                    >
                      First Name
                    </label>
                    <input
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "var(--color-cadetblue-100)",
                        color: "white",
                      }}
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label
                      for=""
                      className="form-label"
                      style={{ fontSize: "var(--font-size-base)" }}
                    >
                      Last Name
                    </label>
                    <input
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "var(--color-cadetblue-100)",
                        color: "white",
                      }}
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="lastName"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12">
                    <label
                      for="email"
                      className="form-label"
                      style={{ fontSize: "var(--font-size-base)" }}
                    >
                      Email
                    </label>
                    <input
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "var(--color-cadetblue-100)",
                        color: "white",
                      }}
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-12">
                    <label
                      for="password"
                      className="form-label"
                      style={{ fontSize: "var(--font-size-base)" }}
                    >
                      Password
                    </label>
                    <input
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "var(--color-cadetblue-100)",
                        color: "white",
                      }}
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                    />
                  </div>
                  <div className={styles.socialLogin}>
                    <div className={`displayNone ${styles.socialIcons}`}>
                      <div className={`displayNone ${styles.iconShapes}`} />
                    </div>
                    <div className={styles.orContinueWith}>
                      or continue with
                    </div>
                    <div className={`displayNone ${styles.socialIcons1}`}>
                      <div className={`displayNone ${styles.socialIconsChild}`} />
                    </div>
                  </div>

                  <div className={styles.googleLogin}>
                    <Link
                      onClick={() => login()}
                      className={`text-decoration-none ${styles.loginregisterWith}`}
                      style={{ color: "var(--color-cadetblue-100)" }}
                    >
                      <img
                        className={styles.googleIcon}
                        alt=""
                        src="/google.svg"
                      />
                      <div className={styles.google}>Google</div>
                    </Link>
                  </div>

                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      onClick={submit}
                      className="btn text-white fw-bold"
                      style={{
                        width: "300px",
                        backgroundColor: "var(--color-cadetblue)",
                      }}
                    >
                      Create Account
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}</div>
        {loading ? <Spinner /> : null}
        {/* email verification step: */}
        {step === 2 && (
          <div className="co-md-5">
            <div className="">
              <div
                className="container text-white p-3 rounded-4"
                style={{ backgroundColor: "var(--color-gray-200)" }}
              >
                <div className="mb-3">
                  <h4
                    className="resText"
                    style={{ fontSize: "var(--font-size-13xl)" }}
                  >
                    Verify Your Email Address
                  </h4>
                </div>

                <form className="row g-3">
                  <div className="col-12">
                    <label
                      for="verification_code"
                      className="form-label"
                      style={{ fontSize: "var(--font-size-base)" }}
                    >
                      Verification Code
                    </label>
                    <input
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "var(--color-cadetblue-100)",
                        color: "white",
                      }}
                      type="password"
                      className="form-control"
                      id="verification_code"
                      name="verification_code"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      onClick={submit}
                      className="btn text-white fw-bold"
                      style={{
                        width: "300px",
                        backgroundColor: "var(--color-cadetblue)",
                      }}
                    >
                      Verify
                    </button>

                    <div className={`${styles.socialLogin} mt-3`}>
                      <div className={styles.socialIcons}>
                        <div className={styles.iconShapes} />
                      </div>
                      <CountdownTimer credentials={credentials} />
                      <div className={styles.socialIcons1}>
                        <div className={styles.socialIconsChild} />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {loading ? <Spinner /> : null}
      </div>
    </section>
  );
};

export default StartFreeTrialContent;
